import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../../components/layout';
import HeroTitle from '../../components/HeroTitle';
import BoldTitle from '../../components/BoldTitle';
import { CommonContext } from '../../context';

export const query = graphql`
  {
    allWpJobPost(sort: { fields: date, order: DESC }) {
      nodes {
        slug
        jobPost {
          designation
          department
          nature
        }
      }
    }
  }
`;

function CareersPage({ data, location }) {
  const jobPosts = data.allWpJobPost.nodes;
  const volunteerPosts = [];

  const renderOpeningCard = function (item, isLastItem) {
    return (
      <div
        className={`p-8 bg-neutral shadow-xl rounded-2xl w-full md:flex md:items-center text-center md:text-left ${
          isLastItem ? 'mt-6 md:mt-8 mb-8 md:mb-16' : 'my-6 md:my-8'
        }`}
      >
        <div className="md:flex-grow">
          <div className="mb-2 text-xl md:text-3xl">
            {item.jobPost.designation}
          </div>
          <div className="mb-2 leading-7 capitalize">
            {item.jobPost.department}
          </div>
          <div className="mb-8 text-sm italic leading-6 capitalize md:mb-2 text-neutral-5">
            {item.jobPost.nature}
          </div>
        </div>
        <Link to={`/career-volunteer/${item.slug}`}>
          <button className="h-12 rounded-full w-36 bg-primary text-neutral ">
            Read More
          </button>
        </Link>
      </div>
    );
  };

  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout title="Career &amp; Volunteer" location={location}>
        <div className="px-6 py-16 bg-gradient-to-r from-primary-2 to-primary-1 md:py-24 md:px-0">
          <HeroTitle
            className="mx-auto md:text-center"
            text="Career &amp; Volunteer"
          />
        </div>
        <div className="flex justify-center w-full">
          <div className="w-full max-w-lg px-6 py-16 text-center md:px-0 md:py-24">
            <div className="mb-6 text-3xl md:text-5xl font-extraBold text-neutral-7 md:mb-8">
              Current Openings
            </div>
            <div className="">
              <p>
                This blessed work was initiated by two young men to spread the
                dawah of Islam to people through modern technology. In{' '}
                <b>2015</b>, we launched our first app <b>Dua &amp; Zikr</b> on
                Google Play Store, receiving an overwhelming response.
              </p>
              <br />
              <p>
                Later on, we launched our <b>Quran</b> and <b>Hadith</b> apps,
                and they have become hugely popular throughout the world. Right
                now, our apps have reached <b>more than 3.5 Million people</b>,
                Alhamdulillah.
              </p>
              <br />
              <p>
                Contribute to this blessed cause and earn rewards as Sadaqah
                Jariyah in the hereafter, in sha Allah!
              </p>
            </div>
          </div>
        </div>

        <div className="flex justify-center w-full bg-neutral-1">
          <div className="w-full max-w-6xl px-6 py-16 xl:px-0 md:py-24">
            {jobPosts.length !== 0 && (
              <React.Fragment>
                <div className="mb-6 text-3xl text-center font-extraBold md:text-5xl md:text-left md:mb-8">
                  Career
                </div>
                {jobPosts.map((item, index) => {
                  return renderOpeningCard(item, index === jobPosts.length - 1);
                })}
              </React.Fragment>
            )}
            {volunteerPosts.length !== 0 && (
              <React.Fragment>
                <div className="mb-6 text-3xl text-center font-extraBold md:text-5xl md:text-left md:mb-8">
                  Volunteer
                </div>
                {volunteerPosts.map((item, index) => {
                  const data = item.frontmatter;
                  return renderOpeningCard(data, index === jobPosts.length - 1);
                })}
              </React.Fragment>
            )}
          </div>
        </div>

        <div className="flex items-center justify-center bg-neutral">
          <div className="w-full max-w-6xl px-6 my-20">
            <BoldTitle className="text-center md:text-center">
              Can&apos;t Find What You&apos;re Looking for?
            </BoldTitle>
            <div className="my-6 text-center font-regular md:my-8">
              Feel Free to Reach out to Us!
            </div>
            <div className="text-center">
              <Link to="/contact">
                <button
                  className={
                    'bg-secondary w-40 rounded-full text-neutral h-12 font-regular text-sm'
                  }
                >
                  <div className="inline-block w-auto mx-auto">Contact us</div>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    </CommonContext.Provider>
  );
}

CareersPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default CareersPage;
